import { useChangePassword } from 'api/user'
import useAuth from 'hook/useAuth'
import { useState } from 'react'
import Button from './button'
import { PasswordField } from './form-elements'
import Modal from './modal'

export default function PassResetModal({ modalOpen, closeModal }) {
  const { loggedInUserInfo } = useAuth()
  const [error, setError] = useState(null)
  const mutation = useChangePassword()

  async function handleChangePassword(event) {
    event.preventDefault()
    const { oldPassword, password, confirmPassword } = event.target.elements

    if (oldPassword.value === password.value) {
      setError('Cannot reuse old password as current password')
    } else if (password.value !== confirmPassword.value) {
      setError('Password not match!!!')
    } else {
      setError(null)
      mutation.mutate({
        password: password.value,
        oldPassword: oldPassword.value,
        _id: loggedInUserInfo?._id,
        closeModal,
      })
    }
  }

  return (
    <Modal
      isOpen={modalOpen}
      closeModal={closeModal}
      title='Change Password'
      medium='medium'
      className='max-h-[95vh] overflow-y-auto custom-scrollbar'
    >
      <form onSubmit={handleChangePassword}>
        {error && <p className='text-red-500 text-sm text-center font-bold mb-4'>{error}</p>}
        {mutation?.error?.response?.status === 406 && (
          <p className='text-red-500 text-sm text-center font-bold mb-4'>
            {mutation?.error?.response?.data?.message}
          </p>
        )}
        <PasswordField
          label='Old Password'
          name='oldPassword'
          placeholder='Enter password'
          minLength={8}
          required
        />
        <PasswordField
          label='New Password'
          name='password'
          placeholder='Enter password'
          pattern='(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}'
          required
        />

        <ul className='text-xs text-red-900 font-semibold ml-4 mb-2'>
          <li className='list-disc'>at least 1 digit</li>
          <li className='list-disc'>at least 1 lowercase letter</li>
          <li className='list-disc'>at least 1 uppercase letter</li>
          <li className='list-disc'>at least 1 symbol</li>
          <li className='list-disc'>at least 8 characters</li>
        </ul>

        <PasswordField
          label='Confirm Password'
          name='confirmPassword'
          placeholder='Enter confirm password'
          pattern='(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}'
          required
        />
        <div className='sm:flex sm:flex-row-reverse sm:px-6 gap-3'>
          <Button type='submit' className='flex justify-center items-center mb-3 md:mb-0'>
            {mutation.isLoading ? 'loading...' : 'Send'}
          </Button>
          <Button type='button' onClick={closeModal}>
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  )
}
