import facebook from 'assets/images/facebook.png'
import instagram from 'assets/images/insta.png'
import linkedin from 'assets/images/linkdin.png'
import twitter from 'assets/images/twitter.png'
import logo from 'assets/images/wide-logo.png'
import youtube from 'assets/images/youtube.png'
import Container from '../container'

export default function Footer() {
  return (
    <footer id='contact-us'>
      <div className='bg-gray-900 text-white py-10 md:py-20 text-sm md:text-base'>
        <Container>
          <div className=''>
            <img src={logo} className='w-52 md:w-72 mx-auto' alt='' />
          </div>
          <div className='md:flex md:justify-center py-5 md:py-10'>
            <div className='text-center md:text-end relative md:right-16'>
              <p>(18/1/4) Farrag Mall, Central Axis</p>
              <p>2nd District, 6th October, Giza, Egypt</p>
            </div>
            <div className='text-center md:text-start mt-2 md:mt-0'>
              <p>Phone: (+20) 2 38367340/9</p> <p>Email: info@afro-group.com</p>
            </div>
          </div>
          <div className='flex justify-center space-x-4 md:space-x-8 mt-4'>
            <a href='https://www.facebook.com/afrosocial247' target='_blank' rel='noreferrer'>
              <img
                src={facebook}
                className='rounded-md hover:scale-110 transition-all ease-in-out duration-500 w-10 md:w-auto'
                alt=''
              />
            </a>
            <a href='https://www.instagram.com/afrosocial247' target='_blank' rel='noreferrer'>
              <img
                src={instagram}
                className='rounded-md hover:scale-110 transition-all ease-in-out duration-500 w-10 md:w-auto'
                alt=''
              />
            </a>
            <a
              href='https://www.linkedin.com/company/afrosocial247'
              target='_blank'
              rel='noreferrer'
            >
              <img
                src={linkedin}
                className='rounded-md hover:scale-110 transition-all ease-in-out duration-500 w-10 md:w-auto'
                alt=''
              />
            </a>
            <a href='https://twitter.com/afrosocial247' target='_blank' rel='noreferrer'>
              <img
                src={twitter}
                className='rounded-md hover:scale-110 transition-all ease-in-out duration-500 w-10 md:w-auto'
                alt=''
              />
            </a>
            <a
              href='https://www.youtube.com/channel/UC-sdlva6q-MZhUqwtTcr8TQ'
              target='_blank'
              rel='noreferrer'
            >
              <img
                src={youtube}
                className='rounded-md hover:scale-110 transition-all ease-in-out duration-500 w-10 md:w-auto'
                alt=''
              />
            </a>
          </div>
        </Container>
      </div>
      <div className='py-6'>
        <p className='text-center text-sm text-gray-900'>
          ©{new Date().getFullYear()} All Rights Reserved. Developed by{' '}
          <a
            href='https://take.win'
            className='font-bold text-gray-950'
            target='_blank'
            rel='noreferrer'
          >
            Take.win
          </a>
        </p>
      </div>
    </footer>
  )
}
