/* eslint-disable react/jsx-filename-extension */
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import FullPageSpinner from 'components/full-page-spinner'
import Auth from 'hook/auth'
import React from 'react'
import ReactDOM from 'react-dom/client'
import 'react-modal-video/css/modal-video.min.css'
import { BrowserRouter } from 'react-router-dom'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import App from './App'
import './index.css'
import './interceptors/axios'
// import reportWebVitals from './reportWebVitals'
// import * as serviceWorkerRegistration from './serviceWorkerRegistration'

const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <QueryClientProvider client={queryClient}>
    <Auth>
      <BrowserRouter>
        <React.Suspense fallback={<FullPageSpinner />}>
          <App />
        </React.Suspense>
      </BrowserRouter>
    </Auth>
  </QueryClientProvider>,
)

// serviceWorkerRegistration.register()
// reportWebVitals()
