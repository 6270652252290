import { useResetPassword } from 'api/authentication'
import signIn from 'assets/images/sign-in.png'
import logo from 'assets/images/wide-logo-2.png'
import Container from 'components/container'
import { PasswordField } from 'components/form-elements'
import Footer from 'components/home/footer'
import Navbar from 'components/home/navbar'
import { useScrollTop } from 'hook/useScrollTop'
import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'

export default function ResetPassword() {
  const { code, token } = useParams()
  const [error, setError] = useState(null)
  const { mutate, isLoading } = useResetPassword()

  async function handleSubmit(event) {
    event.preventDefault()
    const { password, confirmPassword } = event.target.elements
    if (password.value === confirmPassword.value) {
      setError(null)
      mutate({ password: password.value, code, token })
    } else {
      setError('Password not match!!!')
    }
  }

  useScrollTop()

  return (
    <main className=''>
      <div>
        <Navbar />
      </div>
      <Container className='py-12 xl:py-20'>
        <div className='grid grid-cols-12 border rounded-md shadow-md'>
          <div className='col-span-12 lg:col-span-6 relative'>
            <img src={signIn} className='xl:rounded-tl-md xl:rounded-bl-md w-full' alt='' />
            <p className='text-xs absolute bottom-4 md:bottom-10 lg:bottom-16 xl:bottom-8 left-24 mg:left-72 lg:left-44 xl:left-52 text-white'>
              Developed by Take.win
            </p>
          </div>
          <div className='col-span-12 lg:col-span-6 my-8 md:my-auto'>
            <form className='p-4 md:p-20 xl:p-12' onSubmit={handleSubmit}>
              <Link to='/' className='text-2xl font-semibold text-white'>
                <img src={logo} className='w-40 h-10 mx-auto' alt='' />
              </Link>

              <p className='mt-3 mb-6 text-xl font-semibold text-center'>Enter your Password</p>
              {error && <p className='text-red-500 text-sm text-center font-bold'>{error}</p>}
              <PasswordField
                label='Password'
                name='password'
                placeholder='Enter password'
                className='border shadow-md'
                pattern='(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}'
                required
                autoFocus
              />
              <ul className='text-xs text-red-900 font-semibold ml-4 mb-2'>
                <li className='list-disc'>at least 1 digit</li>
                <li className='list-disc'>at least 1 lowercase letter</li>
                <li className='list-disc'>at least 1 uppercase letter</li>
                <li className='list-disc'>at least 1 symbol</li>
                <li className='list-disc'>at least 8 characters</li>
              </ul>
              <PasswordField
                label='Conform Password'
                name='confirmPassword'
                placeholder='Enter confirm password'
                className='border shadow-md'
                pattern='(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}'
                required
              />

              <button
                disabled={isLoading}
                type='submit'
                className='w-full py-2 px-6 bg-gray-900 text-white rounded-md mt-4 border border-gray-900 font-bold hover:bg-transparent hover:text-gray-900 transition-all duration-500 ease-in-out'
              >
                {isLoading ? `Submitting...` : 'Submit'}
              </button>
            </form>
          </div>
        </div>
      </Container>
      <div>
        <Footer />
      </div>
    </main>
  )
}
