import { Menu, Transition } from '@headlessui/react'
import { useLogout } from 'api/authentication'
import { useGetUserById } from 'api/user'
import useAuth from 'hook/useAuth'
import { Fragment, useState } from 'react'
import { HiOutlineChevronDown, HiOutlineLogout, HiOutlinePencil } from 'react-icons/hi'
import { HiOutlineLockClosed } from 'react-icons/hi2'
import { useLocation } from 'react-router-dom'
import { IMAGE_URL } from 'utilities/base-url'
import PassResetModal from './pass-reset-modal'
import ProfileUpdateModal from './profile-update-modal'

export default function ProfileDropdown() {
  const [profileModal, setProfileModal] = useState(false)
  const [passwordModal, setPasswordModal] = useState(false)
  const { loggedInUserInfo } = useAuth()
  const { data, refetch, isLoading } = useGetUserById(loggedInUserInfo?._id)
  const { mutate } = useLogout()
  const location = useLocation()

  const handleLogout = () => {
    mutate()
  }

  const hasProfileImage = data?.user?.profileImageURL
  const imageURL = hasProfileImage
    ? `${IMAGE_URL}${data?.user?.profileImageURL}`
    : 'https://lms.rocket-soft.org/store/995/avatar/6179b58ee64c7.png'

  if (isLoading) {
    return <p>loading...</p>
  }
  return (
    <div>
      <Menu as='div' className='relative inline-block text-left'>
        <div>
          <Menu.Button className='inline-flex w-full justify-center items-center'>
            <picture>
              <img
                src={imageURL}
                className='rounded-full h-10 md:mr-4 border-2 border-white shadow-sm'
                alt='profile'
              />
            </picture>
            {location.pathname !== '/' && (
              <>
                <span className='hidden md:block font-medium text-gray-700'>
                  {loggedInUserInfo?.name}
                </span>
                <HiOutlineChevronDown className='ml-2 h-4 w-4 text-gray-700' />
              </>
            )}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform scale-95'
          enterTo='transform scale-100'
          leave='transition ease-in duration=75'
          leaveFrom='transform scale-100'
          leaveTo='transform scale-95'
        >
          <Menu.Items className='absolute right-0 w-56 z-50 mt-2 origin-top-right bg-white rounded shadow-sm'>
            <div className='p-1'>
              <Menu.Item>
                <button
                  className='flex hover:bg-orange-500 hover:text-white text-gray-700 rounded p-2 text-sm group transition-colors items-center w-full'
                  onClick={() => {
                    setProfileModal(true)
                  }}
                >
                  <HiOutlinePencil className='h-4 w-4 mr-2' />
                  Edit Profile
                </button>
              </Menu.Item>
              <Menu.Item>
                <button
                  className='flex hover:bg-orange-500 hover:text-white text-gray-700 rounded p-2 text-sm group transition-colors items-center w-full'
                  onClick={() => {
                    setPasswordModal(true)
                  }}
                >
                  <HiOutlineLockClosed className='h-4 w-4 mr-2' />
                  Change Password
                </button>
              </Menu.Item>
              <Menu.Item>
                <button
                  className='flex hover:bg-orange-500 hover:text-white text-gray-700 rounded p-2 text-sm group transition-colors items-center w-full'
                  onClick={handleLogout}
                >
                  <HiOutlineLogout className='h-4 w-4 mr-2' />
                  Logout
                </button>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      <ProfileUpdateModal
        user={data?.user}
        refetch={refetch}
        isOpen={profileModal}
        closeModal={() => setProfileModal(!profileModal)}
      />
      <PassResetModal
        modalOpen={passwordModal}
        closeModal={() => setPasswordModal(!passwordModal)}
      />
    </div>
  )
}
