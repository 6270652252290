/* eslint-disable no-unused-expressions */
import logo from 'assets/images/wide-logo.png'
import useAuth from 'hook/useAuth'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Container from '../container'
import ProfileDropdown from '../profile-dropdown'
import SideNavbar from '../side-navbar'

export default function Navbar() {
  const { loggedInUserInfo } = useAuth()
  const [open, setOpen] = useState(false)
  const [isSticky, setIsSticky] = useState(true)
  const [prevScrollPos, setPrevScrollPos] = useState(0)

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset
      const isScrolledDown = prevScrollPos < currentScrollPos

      setPrevScrollPos(currentScrollPos)

      if (isScrolledDown && currentScrollPos > 50) {
        setIsSticky(false)
      } else {
        setIsSticky(true)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [prevScrollPos])

  const handleOpen = () => {
    setOpen(!open)
  }

  return (
    <div className='pb-14 md:pb-16 lg:pb-20'>
      <nav
        className={`fixed top-0 left-0 w-full bg-gray-900 text-white transition-transform duration-300 ${
          isSticky ? '' : 'transform -translate-y-full'
        }`}
        style={{ zIndex: '9999' }}
      >
        <Container>
          <div className='flex items-center justify-between h-14 md:h-16 lg:h-20'>
            <Link to='/' className='text-2xl font-semibold text-white'>
              <img src={logo} className='w-40 h-10' alt='' />
            </Link>

            <div className='hidden lg:block'>
              <div className='ml-10 flex items-center space-x-8 text-lg'>
                {loggedInUserInfo ? (
                  <Link to={loggedInUserInfo?.role === 'admin' ? '/admin' : '/dashboard'}>
                    Dashboard
                  </Link>
                ) : (
                  <Link to='/'>Home</Link>
                )}

                <a href='#about'>About</a>
                <a href='#video-gallery'>Video</a>
                <a href='#photo-gallery'>Photo</a>
                <a href='#contact-us'>Contact</a>

                {!loggedInUserInfo ? (
                  <Link
                    to='/sign-in'
                    className={({ isActive }) =>
                      `${isActive ? 'underline' : null} hover:text-gray-500`
                    }
                  >
                    Login
                  </Link>
                ) : null}

                <div className='hidden lg:block'>
                  {loggedInUserInfo ? <ProfileDropdown /> : null}
                </div>
              </div>
            </div>

            <div className='block lg:hidden'>
              <button
                className='p-1 border-2 border-transparent text-gray-400 rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-gray-700'
                onClick={() => setOpen(!open)}
              >
                <svg
                  className='h-6 w-6'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={2}
                    d='M4 6h16M4 12h16M4 18h16'
                  />
                </svg>
              </button>
            </div>
          </div>
        </Container>
      </nav>
      <SideNavbar open={open} handleOpen={handleOpen} loggedInUserInfo={loggedInUserInfo} />
    </div>
  )
}
