import useAuth from 'hook/useAuth'
import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import ForgotPassword from './forgot-password'
import ResetPassword from './reset-password'

const Home = React.lazy(() => import('./home'))

// admin page
const AdminDashboard = React.lazy(() => import('./admin-dashboard'))
const StudentQuestion = React.lazy(() => import('./student-question'))
const TeacherQuestion = React.lazy(() => import('./teacher-question'))
const ManageStudent = React.lazy(() => import('./manage-student'))
const ManageTeacher = React.lazy(() => import('./manage-teacher'))
const ManageCourse = React.lazy(() => import('./manage-course'))
const ManageBatch = React.lazy(() => import('./manage-batch'))
const ManageCertificate = React.lazy(() => import('./manage-certificate'))
const AccountVerify = React.lazy(() => import('./account-verify'))
const Certificate = React.lazy(() => import('./certificate'))
const ManagePhotoGallery = React.lazy(() => import('./manage-photo-gallery'))
const ManageHome = React.lazy(() => import('./manage-home'))
const RegistrationInfo = React.lazy(() => import('./registration-info'))

// authentication page
const SignIn = React.lazy(() => import('./sign-in'))
const SignUp = React.lazy(() => import('./sign-up'))

// student page
const Student = React.lazy(() => import('./student/student'))
const MyQuiz = React.lazy(() => import('./student/my-quiz'))
const QuizById = React.lazy(() => import('./student/quiz-by-id'))

// teacher page
const Teacher = React.lazy(() => import('./teacher/teacher'))
const ManageQuiz = React.lazy(() => import('./teacher/manage-quiz'))

// shared page
const Dashboard = React.lazy(() => import('./dashboard'))
const MyCourse = React.lazy(() => import('./my-course'))
const CourseById = React.lazy(() => import('./course-by-id'))

// not found page
const NotFound = React.lazy(() => import('./not-found'))

export default function Router() {
  const { loggedInUserInfo } = useAuth()
  return (
    <div>
      <Routes>
        <Route path='' element={<Home />} />

        {loggedInUserInfo?.role === 'teacher' || loggedInUserInfo?.role === 'student' ? (
          <Route
            path='/dashboard'
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          >
            <Route
              path='teacher-review'
              element={
                <PrivateRoute>
                  <Student />
                </PrivateRoute>
              }
            />
            <Route
              path='student-review'
              element={
                <PrivateRoute>
                  <Teacher />
                </PrivateRoute>
              }
            />
            <Route
              path='courses'
              element={
                <PrivateRoute>
                  <MyCourse />
                </PrivateRoute>
              }
            />
            <Route
              path='course/:batchId/:courseId'
              element={
                <PrivateRoute>
                  <CourseById />
                </PrivateRoute>
              }
            />

            <Route
              path='manage-quiz'
              element={
                <PrivateRoute>
                  <ManageQuiz />
                </PrivateRoute>
              }
            />

            <Route
              path='quiz'
              element={
                <PrivateRoute>
                  <MyQuiz />
                </PrivateRoute>
              }
            />

            <Route
              path='quiz/:id'
              element={
                <PrivateRoute>
                  <QuizById />
                </PrivateRoute>
              }
            />
          </Route>
        ) : null}

        {loggedInUserInfo?.role === 'admin' ? (
          <Route
            path='/admin'
            element={
              <PrivateRoute>
                <AdminDashboard />
              </PrivateRoute>
            }
          >
            <Route
              path='manage-batch'
              element={
                <PrivateRoute>
                  <ManageBatch />
                </PrivateRoute>
              }
            />
            <Route
              path='student-question'
              element={
                <PrivateRoute>
                  <StudentQuestion />
                </PrivateRoute>
              }
            />
            <Route
              path='manage-student'
              element={
                <PrivateRoute>
                  <ManageStudent />
                </PrivateRoute>
              }
            />
            <Route
              path='manage-teacher'
              element={
                <PrivateRoute>
                  <ManageTeacher />
                </PrivateRoute>
              }
            />
            <Route
              path='teacher-question'
              element={
                <PrivateRoute>
                  <TeacherQuestion />
                </PrivateRoute>
              }
            />
            <Route
              path='manage-course'
              element={
                <PrivateRoute>
                  <ManageCourse />
                </PrivateRoute>
              }
            />
            <Route
              path='student-certificate'
              element={
                <PrivateRoute>
                  <ManageCertificate />
                </PrivateRoute>
              }
            />
            <Route
              path='manage-photo-gallery'
              element={
                <PrivateRoute>
                  <ManagePhotoGallery />
                </PrivateRoute>
              }
            />

            <Route
              path='manage-home'
              element={
                <PrivateRoute>
                  <ManageHome />
                </PrivateRoute>
              }
            />

            <Route
              path='registration-info'
              element={
                <PrivateRoute>
                  <RegistrationInfo />
                </PrivateRoute>
              }
            />
          </Route>
        ) : null}

        <Route path='/certificate/:courseCode/:studentId/:studentName' element={<Certificate />} />
        <Route path='/verify-account/:id' element={<AccountVerify />} />
        <Route path='/sign-in' element={<SignIn />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/reset-password/:code/:token' element={<ResetPassword />} />
        <Route path='/register' element={<SignUp />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </div>
  )
}

function PrivateRoute({ children }) {
  const { loggedInUserInfo } = useAuth()
  return loggedInUserInfo ? children : <Navigate to='/sign-in' />
}
