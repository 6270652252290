import { QueryCache, useMutation } from '@tanstack/react-query'
import axios from 'axios'
import useAuth from 'hook/useAuth'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { BASE_URL } from 'utilities/base-url'

export function useLogin() {
  const navigate = useNavigate()
  const { updateLoggedInUserInfo } = useAuth()
  return useMutation({
    mutationFn: async ({ email, password, userId }) => {
      try {
        const response = await axios.post(
          `${BASE_URL}/auth/login`,
          { email, password, userId },
          { withCredentials: true },
        )

        if (response.status === 200) {
          Cookies.set('accessToken', response.data.token)
          // eslint-disable-next-line dot-notation
          axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`
          localStorage.setItem('trainingUserInfo', JSON.stringify(response?.data?.user))
          updateLoggedInUserInfo(response.data?.user)
          if (response?.data?.user?.role === 'admin') {
            navigate(`/admin`)
          }
          if (
            response?.data?.user?.role === 'teacher' ||
            response?.data?.user?.role === 'student'
          ) {
            navigate(`/dashboard`)
          }
        }
      } catch (error) {
        if (error.response.status === 400) {
          toast.error(error.response.data.message)
        }
      }
    },
  })
}

export function useLogout() {
  const { updateLoggedInUserInfo } = useAuth()
  const queryCache = new QueryCache()
  const navigate = useNavigate()
  return useMutation({
    mutationFn: async () => {
      try {
        const response = await axios.post(`${BASE_URL}/auth/logout`, {}, { withCredentials: true })
        if (response.status === 200) {
          // eslint-disable-next-line dot-notation
          axios.defaults.headers.common['Authorization'] = ''
          localStorage.removeItem('trainingUserInfo')
          updateLoggedInUserInfo(null)
          Cookies.remove('accessToken')
          queryCache.clear()
          navigate('/')
        }
      } catch (error) {
        toast.error('Something went wrong while trying to logout you')
      }
    },
  })
}

export function useForgotPassword() {
  return useMutation({
    mutationFn: async ({ email, event }) => {
      try {
        const result = await axios.post(`${BASE_URL}/user/forgot-password`, { email })
        if (result.data.success) {
          toast.success(result.data.message)
          event.target.reset()
        } else {
          toast.error(result.data.message)
        }
      } catch (err) {
        if (err.response.data.success === false) {
          toast.error(err.response.data.message)
        }
      }
    },
  })
}

export function useResetPassword() {
  const navigate = useNavigate()
  return useMutation({
    mutationFn: async ({ password, code, token }) => {
      try {
        const result = await axios.post(`${BASE_URL}/user/update-password`, {
          password,
          code,
          token,
        })
        if (result.data.success) {
          toast.success(result.data.message)
          navigate('/sign-in')
        } else {
          toast.error(result.data.message)
        }
        return result
      } catch (err) {
        if (err.response.data.success === false) {
          if (
            err.response.data.err_message === 'invalid signature' ||
            err.response.data.err_message === 'jwt expired'
          ) {
            toast.error('Sorry!!! url invalid try again...')
          }
        }
        return Promise.reject(err)
      }
    },
  })
}
