/* eslint-disable no-underscore-dangle */
/* eslint-disable dot-notation */
import axios from 'axios'
import Cookies from 'js-cookie'
import { BASE_URL } from 'utilities/base-url'

axios.interceptors.request.use(
  (config) => {
    if (!axios.defaults.headers.common['Authorization']) {
      const token = Cookies.get('accessToken')
      if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      }
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

let isCalledFirstTime = false

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401 && !isCalledFirstTime) {
      isCalledFirstTime = true
      const response = await axios.post(`${BASE_URL}/auth/refresh`, {}, { withCredentials: true })
      if (response.status === 200) {
        // eslint-disable-next-line dot-notation
        axios.defaults.headers.common['Authorization'] = `Bearer ${response?.data?.token}`
        Cookies.remove('accessToken')
        Cookies.set('accessToken', response?.data?.token)
        return axios(error.config)
      }
    }
    isCalledFirstTime = false
    return Promise.reject(error)
  },
)
