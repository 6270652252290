import * as React from 'react'

export const AuthContext = React.createContext()

export default function Auth({ children }) {
  const [loggedInUserInfo, setLoggedInUserInfo] = React.useState(
    () => JSON.parse(localStorage.getItem('trainingUserInfo')) || null,
  )

  function updateLoggedInUserInfo(info) {
    setLoggedInUserInfo(info)
  }
  const value = React.useMemo(
    () => ({ loggedInUserInfo, updateLoggedInUserInfo }),
    [loggedInUserInfo],
  )
  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
  )
}
