/* eslint-disable jsx-a11y/label-has-associated-control */

import { useUpdateUserProfile } from 'api/user'
import useAuth from 'hook/useAuth'
import { useState } from 'react'
import { IMAGE_URL } from 'utilities/base-url'
import Button from './button'
import { TextField } from './form-elements'
import Modal from './modal'
import ProfileImageCropper from './profile-image-croper'

export default function MyModal({ user, refetch, isOpen, closeModal }) {
  // const [image, setImage] = useState(null)
  const [profileImage, setProfileImage] = useState(null)
  // const [previewImageURL, setPreviewImageURL] = useState(null)
  // const [imageError, setImageError] = useState('')
  const { mutate, isLoading } = useUpdateUserProfile()
  const { loggedInUserInfo } = useAuth()

  // const handleImageChange = (event) => {
  //   if (event.target.files[0]?.size > 5000000) {
  //     return setImageError('Not more then 5 MB')
  //   }
  //   if (event.target.files[0]) {
  //     setImageError('')
  //     setPreviewImageURL(URL.createObjectURL(event.target.files[0]))
  //     return setImage(event.target.files[0])
  //   }
  //   return setImageError('')
  // }

  async function handleSubmit(event) {
    event.preventDefault()
    const userFormData = new FormData()
    const { name, email, phoneNumber, gender, dob } = event.target.elements
    userFormData.append('_id', loggedInUserInfo?._id)
    userFormData.append('name', name.value || '')
    userFormData.append('email', email.value || '')
    userFormData.append('phoneNumber', phoneNumber.value || '')
    userFormData.append('gender', gender.value || '')
    userFormData.append('dob', dob.value || '')
    if (profileImage) {
      userFormData.append('profileImageURL', profileImage)
    }
    mutate({ userFormData, refetch, closeModal, setProfileImage })
  }

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      title='Edit Profile'
      className='max-h-[95vh] overflow-y-auto custom-scrollbar'
    >
      <form onSubmit={handleSubmit} className=''>
        <TextField
          label='Name'
          name='name'
          type='text'
          defaultValue={user?.name}
          placeholder='Enter Name'
        />
        <TextField
          label='Email'
          name='email'
          type='email'
          defaultValue={user?.email}
          placeholder='Enter Email'
        />

        <TextField
          label='Number'
          type='text'
          name='phoneNumber'
          defaultValue={user?.phoneNumber}
          placeholder='Enter phone number'
        />

        <div className='mb-2'>
          <label htmlFor='gender' className='block text-gray-700 font-semibold text-base mb-1'>
            Gender
          </label>
          <div className='block w-full bg-gray-100 py-3 px-4 placeholder:text-slate-500 font-medium rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-500'>
            <input
              type='radio'
              name='gender'
              value='male'
              defaultChecked={user?.gender === 'male'}
              id=''
            />{' '}
            Male &nbsp; &nbsp; &nbsp;
            <input
              type='radio'
              name='gender'
              value='female'
              defaultChecked={user?.gender === 'female'}
              id=''
            />{' '}
            Female &nbsp; &nbsp; &nbsp;
            <input
              type='radio'
              name='gender'
              value='others'
              defaultChecked={user?.gender === 'others'}
              id=''
            />{' '}
            Others
          </div>
        </div>

        <div className='mb-2'>
          <label htmlFor='dob' className='block text-gray-700 font-semibold text-base mb-1'>
            Date of Birth
          </label>
          <input
            type='date'
            name='dob'
            defaultValue={
              user?.dob
                ? user?.dob
                : new Date(new Date().setDate(new Date().getDate() - 4745))
                    .toISOString()
                    .split('T')[0]
            }
            className='block w-full bg-gray-100 py-3 px-4 placeholder:text-slate-500 font-medium rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-500'
          />
        </div>

        <ProfileImageCropper
          previousImageURL={`${IMAGE_URL}${user?.profileImageURL}`}
          setProfileImage={setProfileImage}
        />

        {/* image related code start */}
        {/* {previewImageURL ? (
          <div className='relative group'>
            <img src={previewImageURL} alt='preview' className='rounded-md' />
            <div className='absolute top-0 right-0'>
              <GiSkullCrossedBones
                size={24}
                className='bg-white text-red-900 cursor-pointer group-hover:animate-spin transition-all ease-in-out duration-500'
                onClick={() => {
                  setImage(null)
                  setPreviewImageURL(null)
                }}
              />
            </div>
          </div>
        ) : null}

        <div className='mb-3 relative'>
          <div className='mb-1'>
            <label htmlFor='image' className='block text-gray-700 font-semibold text-base mb-1'>
              Image
            </label>
          </div>
          <div className='relative'>
            <label
              htmlFor='image'
              className='h-auto md:h-36 w-full border-2 border-indigo-300 bg-[#F5F8FF] rounded-lg border-dotted  mx-auto flex justify-center items-center text-center cursor-pointer mb-4'
            >
              <div className='text-center'>
                <div className='h-12 w-12 mx-auto'>
                  <img src={pictureIcon} alt='pictureicon' className='h-full w-full' />
                </div>
                <p className='text-base font-bold'>Browse to choose a image</p>
                <p className='text-tertiary font-medium text-sm'>Supports: PNG, JPEG, JPG</p>
                <p className='text-tertiary font-medium text-sm'>
                  {598}x{373} Recommended dimension
                </p>
              </div>
            </label>
            <input
              type='file'
              id='image'
              name='image'
              accept='/image*'
              className='w-full h-28 bg-green-500 absolute top-0 opacity-0 cursor-pointer'
              onChange={handleImageChange}
            />
          </div>
          {imageError ? (
            <p className='text-red-500 font-bold text-center'>
              <small>{imageError}</small>
            </p>
          ) : null}
        </div> */}
        {/* image related code end */}

        <div className='sm:flex sm:flex-row-reverse sm:px-6 gap-3 mt-6'>
          <Button type='submit' className='flex justify-center items-center mb-3 md:mb-0'>
            {isLoading ? 'loading...' : 'Update'}
          </Button>
          <Button type='button' onClick={closeModal}>
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  )
}
