/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useCallback, useState } from 'react'
import Cropper from 'react-easy-crop'
import { RiPencilFill } from 'react-icons/ri'
import { getCroppedFile } from 'utilities/helpers'

export default function ProfileImageCropper({ previousImageURL, setProfileImage }) {
  const [showEdit, setShowEdit] = useState(false)
  const [imageSrc, setImageSrc] = useState(null)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setShowEdit(true)
      const reader = new FileReader()
      reader.readAsDataURL(e.target.files[0])
      reader.addEventListener('load', () => {
        setImageSrc(reader.result)
      })
    }
  }

  const onCropComplete = useCallback(
    (_, croppedAreaPixels) => {
      setProfileImage(null)
      getCroppedFile({ imageSrc, crop: croppedAreaPixels, setCroppedImage: setProfileImage })
    },
    [imageSrc, setProfileImage],
  )

  return (
    <div>
      {showEdit ? (
        <div>
          {imageSrc && (
            <div className='relative h-96 mb-6'>
              <div className='h-full'>
                <Cropper
                  image={imageSrc}
                  crop={crop}
                  zoom={zoom}
                  aspect={1}
                  cropShape='rect'
                  cropSize={{ width: 350, height: 350 }}
                  // className='py-4'
                  // objectFit='horizontal-cover'
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </div>
              <div className='block'>
                <label htmlFor='slider' className='mt-2 font-semibold inline-block'>
                  Zoom
                </label>
                <input
                  type='range'
                  value={zoom}
                  min={1}
                  max={3}
                  id='slider'
                  step={0.1}
                  aria-labelledby='Zoom'
                  onChange={(e) => {
                    setZoom(e.target.value)
                  }}
                  className='block mb-10'
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className='flex justify-center'>
          <img src={previousImageURL} alt='preview' className='p-3' />
        </div>
      )}
      <div className={`relative mb-10 ${showEdit ? 'mt-14' : 'mt-2'} flex justify-center`}>
        <button className='mx-auto'>
          <label
            htmlFor='image'
            className='px-4 py-2 gap-1 text-sm font-semibold bg-[#6A53FF] text-white rounded-full flex items-center'
          >
            <span className='flex items-center gap-2 text-md'>
              <RiPencilFill className='text-lg' />
              Change picture
            </span>
            <input
              type='file'
              id='image'
              name='image'
              accept='.jpg, .jpeg, .png'
              className='w-full h-full bg-green-500 absolute top-0 left-0 opacity-0 cursor-pointer'
              onChange={onSelectFile}
            />
          </label>
        </button>
      </div>
    </div>
  )
}
