import Router from 'pages/router'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export default function App() {
  // const location = useLocation()
  // const pathData = [
  //   '/admin',
  //   '/admin/student-question',
  //   '/admin/teacher-question',
  //   '/admin/manage-teacher',
  //   '/admin/manage-student',
  //   '/admin/manage-course',
  //   '/admin/student-certificate',
  //   '/admin/manage-batch',
  // ]
  return (
    <div>
      <ToastContainer
        position='bottom-left'
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />
      {/* <div className='xl:hidden'>
        {pathData.includes(location.pathname) ? (
          <div className='mb-20'>
            <Navbar />
          </div>
        ) : null}
      </div> */}
      <div>
        <Router />
      </div>
    </div>
  )
}
