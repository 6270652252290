import { useMutation, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import useAuth from 'hook/useAuth'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { BASE_URL } from 'utilities/base-url'
import { useLogout } from './authentication'

export function useSignUp() {
  return useMutation({
    mutationFn: async ({ name, email, phoneNumber, discountCode, event }) => {
      try {
        const { data } = await axios.post(`${BASE_URL}/user/registration`, {
          name,
          email,
          phoneNumber,
          discountCode,
        })
        if (data?.success) {
          toast.success(data?.message)
          event.target.reset()
        }
        return data
      } catch (error) {
        if (error?.response?.status === 302) {
          toast.error(error?.response?.data?.message)
          return Promise.reject(error)
        }
        return Promise.reject(error)
      }
    },
  })
}

export function useGetSpecificUser(id, role) {
  return useQuery([`specific-user-${id}`], async () => {
    try {
      const response = await axios.get(`${BASE_URL}/user/get-specific-user/${id}/${role}`)
      return response.data
    } catch (error) {
      return Promise.reject(error)
    }
  })
}

export function useGetUserById(id) {
  return useQuery([`user-${id}`], async () => {
    try {
      const response = await axios.get(`${BASE_URL}/user/get-user/${id}`)
      return response?.data
    } catch (error) {
      return Promise.reject(error)
    }
  })
}

export function useUpdateUserProfile() {
  const { loggedInUserInfo, updateLoggedInUserInfo } = useAuth()
  const { mutate } = useLogout()
  return useMutation({
    mutationFn: async ({ userFormData, refetch, closeModal, setProfileImage }) => {
      try {
        // const headers = {
        //   'content-type': 'multipart/form-data',
        // }
        const response = await axios.put(`${BASE_URL}/user/update`, userFormData)
        refetch()
        const updatedData = { ...loggedInUserInfo, name: response?.data?.name }
        updateLoggedInUserInfo(updatedData)
        localStorage.removeItem('trainingUserInfo')
        localStorage.setItem('trainingUserInfo', JSON.stringify(updatedData))
        setProfileImage(null)
        closeModal()
        toast.success('Profile Updated successful')
      } catch (error) {
        if (error.response.status === 403) {
          mutate()
        }
        toast.error('Something went wrong. Please try again.')
      }
    },
    retry: 2,
  })
}

export function useUpdateUser() {
  const { mutate } = useLogout()
  return useMutation({
    mutationFn: async ({ userFormData, refetch, closeModal }) => {
      try {
        const { data } = await axios.put(`${BASE_URL}/user/update`, userFormData)
        if (data?.success) {
          refetch()
          closeModal()
          toast.success(data?.message)
        }
        return data
      } catch (error) {
        if (error.response.status === 403) {
          mutate()
        }
        toast.error('Something went wrong. Please try again.')
        return Promise.reject(error)
      }
    },
    retry: 2,
  })
}

export function useChangePassword() {
  return useMutation({
    mutationFn: async ({ password, oldPassword, _id, closeModal }) => {
      try {
        const result = await axios.post(`${BASE_URL}/user/change-password`, {
          _id,
          password,
          oldPassword,
        })
        if (result.data.success) {
          toast.success(result.data.message)
          closeModal()
          return result.data
        }
        toast.error(result.data.message)
        return result.data
      } catch (err) {
        if (err.response.data.success === false) {
          toast.error(err.response.data.message)
        }
        return Promise.reject(err)
      }
    },
  })
}

export function useUserAccountVerify() {
  const navigate = useNavigate()
  return useMutation({
    mutationFn: async ({ _id, verifiedId, event }) => {
      try {
        const { data } = await axios.post(`${BASE_URL}/user/confirm-verify-account`, {
          _id,
          verifiedId,
        })
        if (data.success) {
          toast.success(data.message)
          event.target.reset()
          navigate('/sign-in')
        }
        if (!data.success) {
          toast.error(data.message)
        }
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
  })
}

export function useGetAllTeacher() {
  return useQuery([`all-teacher}`], async () => {
    try {
      const { data } = await axios.get(`${BASE_URL}/user/get-all-teacher`)
      return data
    } catch (error) {
      return Promise.reject(error)
    }
  })
}

export function useGetAllStudent() {
  return useQuery([`all-student}`], async () => {
    try {
      const { data } = await axios.get(`${BASE_URL}/user/get-all-student`)
      return data
    } catch (error) {
      return Promise.reject(error)
    }
  })
}

export function useUpdateUserStatus() {
  // const { mutate } = useLogout()
  return useMutation({
    mutationFn: async ({ _id, status, refetch }) => {
      try {
        const { data } = await axios.put(`${BASE_URL}/user/update-user-status`, { _id, status })
        if (data?.success) {
          refetch()
          toast.success(data?.message)
        }
      } catch (error) {
        // if (error.response.status === 403) {
        //   mutate()
        // }
        toast.error('Something went wrong. Please try again.')
      }
    },
    // retry: 2,
  })
}

export function useGetStudentID() {
  const { mutate } = useLogout()
  return useQuery([`student-id`], async () => {
    try {
      const response = await axios.get(`${BASE_URL}/user/get-student-id`)
      return response?.data
    } catch (error) {
      if (error.response.status === 403) {
        mutate()
      }
      return Promise.reject(error)
    }
  })
}

export function useGetTeacherID() {
  const { mutate } = useLogout()
  return useQuery([`teacher-id`], async () => {
    try {
      const response = await axios.get(`${BASE_URL}/user/get-teacher-id`)
      return response?.data
    } catch (error) {
      if (error.response.status === 403) {
        mutate()
      }
      return Promise.reject(error)
    }
  })
}

export function useUpdateTeacherID() {
  // const { mutate } = useLogout()
  return useMutation({
    mutationFn: async ({ _id, teacherId, refetch, closeModal }) => {
      try {
        const { data } = await axios.put(`${BASE_URL}/user/update-teacher-id`, { _id, teacherId })
        if (data?.success) {
          toast.success(data?.message)
          closeModal()
          refetch()
        }
        if (!data?.success) {
          toast.error(data?.message)
        }
      } catch (error) {
        // if (error.response.status === 403) {
        //   mutate()
        // }
        toast.error('Something went wrong. Please try again.')
      }
    },
    // retry: 2,
  })
}

export function useUpdateStudentID() {
  // const { mutate } = useLogout()
  return useMutation({
    mutationFn: async ({ _id, studentId, refetch, closeModal }) => {
      try {
        const { data } = await axios.put(`${BASE_URL}/user/update-student-id`, { _id, studentId })
        if (data?.success) {
          toast.success(data?.message)
          closeModal()
          refetch()
        }
        if (!data?.success) {
          toast.error(data?.message)
        }
      } catch (error) {
        // if (error.response.status === 403) {
        //   mutate()
        // }
        toast.error('Something went wrong. Please try again.')
      }
    },
    // retry: 2,
  })
}

export function useAddUserByAdmin() {
  const { mutate } = useLogout()
  return useMutation({
    mutationFn: async ({
      name,
      email,
      userId,
      password,
      nameByURL,
      role,
      event,
      refetch,
      closeModal,
    }) => {
      try {
        const { data } = await axios.post(`${BASE_URL}/user/add-user-by-admin`, {
          name,
          email,
          userId,
          password,
          nameByURL,
          role,
        })
        if (data?.success) {
          toast.success(data?.message)
          event.target.reset()
          refetch()
          closeModal()
        }
        if (!data?.success) {
          toast.error(data?.message)
        }
        return data
      } catch (error) {
        if (error.response.status === 403) {
          mutate()
        }
        return Promise.reject(error)
      }
    },
    retry: 2,
  })
}

export function useAddCourseResource() {
  const { mutate } = useLogout()
  return useMutation({
    mutationFn: async ({
      formData,
      setUploading,
      setUploadProgress,
      setRemainingTime,
      closeModal,
      setFiles,
      refetch,
    }) => {
      try {
        const config = {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            setUploadProgress(percentCompleted)

            const estimatedRemainingTime = Math.round(
              ((progressEvent.total - progressEvent.loaded) / progressEvent.loaded) * 10,
            )
            setRemainingTime(estimatedRemainingTime)
          },
        }

        setUploading(true)

        const { data } = await axios.post(`${BASE_URL}/user/add-course-resource`, formData, config)
        if (data?.success) {
          refetch()
          setFiles(null)
          setUploading(false)
          setUploadProgress(0)
          setRemainingTime(0)
          toast.success(data?.message)
          closeModal()
        }
        return data
      } catch (error) {
        setUploading(false)
        if (error?.response?.status === 403) {
          mutate()
        }
        return Promise.reject(error)
      }
    },
  })
}

export function useGetCourseResource(batchId, courseId, userId, role) {
  const { mutate } = useLogout()
  return useQuery([`course-resource-${batchId}`], async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/user/get-course-resource/${role}/${batchId}/${courseId}/${userId}`,
      )
      return response?.data
    } catch (error) {
      if (error.response.status === 403) {
        mutate()
      }
      return Promise.reject(error)
    }
  })
}

export function useRemoveFromCourseResource() {
  const { mutate } = useLogout()
  return useMutation({
    mutationFn: async ({ id, resourceId, refetch, setOpenRemoveModal }) => {
      try {
        const response = await axios.delete(
          `${BASE_URL}/user/remove-from-course-resource/${id}/${resourceId}`,
        )
        if (response?.data?.success) {
          refetch()
          toast.success(response?.data?.message)
          setOpenRemoveModal((prevState) => !prevState)
        }
        return response.data
      } catch (error) {
        if (error.response.status === 403) {
          mutate()
        }
        return Promise.reject(error)
      }
    },
    retry: 2,
  })
}

export function useDeleteCourseResource() {
  const { mutate } = useLogout()
  return useMutation({
    mutationFn: async ({ id, refetch, setOpenDeleteModal }) => {
      try {
        const response = await axios.delete(`${BASE_URL}/user/delete-course-resource/${id}`)
        if (response?.data?.success) {
          refetch()
          toast.success(response?.data?.message)
          setOpenDeleteModal((prevState) => !prevState)
        }
        return response.data
      } catch (error) {
        if (error.response.status === 403) {
          mutate()
        }
        return Promise.reject(error)
      }
    },
    retry: 2,
  })
}

export function useGetTeacherDashboardInfo(id) {
  return useQuery([`get-teacher-dashboard-info-${id}`], async () => {
    try {
      const response = await axios.get(`${BASE_URL}/user/get-teacher-dashboard-info/${id}`)
      return response.data
    } catch (error) {
      return Promise.reject(error)
    }
  })
}

export function useGetStudentDashboardInfo(id) {
  return useQuery([`get-student-dashboard-info-${id}`], async () => {
    try {
      const response = await axios.get(`${BASE_URL}/user/get-student-dashboard-info/${id}`)
      return response.data
    } catch (error) {
      return Promise.reject(error)
    }
  })
}

export function useAddPhotoGallery() {
  const { mutate } = useLogout()
  return useMutation({
    mutationFn: async ({
      formData,
      setUploading,
      setUploadProgress,
      setRemainingTime,
      closeModal,
      setFiles,
      refetch,
    }) => {
      try {
        const config = {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            setUploadProgress(percentCompleted)

            const estimatedRemainingTime = Math.round(
              ((progressEvent.total - progressEvent.loaded) / progressEvent.loaded) * 10,
            )
            setRemainingTime(estimatedRemainingTime)
          },
        }

        setUploading(true)

        const { data } = await axios.post(`${BASE_URL}/user/add-photo-gallery`, formData, config)
        if (data?.success) {
          refetch()
          setFiles(null)
          setUploading(false)
          setUploadProgress(0)
          setRemainingTime(0)
          toast.success(data?.message)
          closeModal()
        }
        return data
      } catch (error) {
        setUploading(false)
        if (error?.response?.status === 403) {
          mutate()
        }
        return Promise.reject(error)
      }
    },
  })
}

export function useGetPhotoGallery() {
  return useQuery([`photo-gallery`], async () => {
    try {
      const { data } = await axios.get(`${BASE_URL}/user/get-photo-gallery`)
      return data
    } catch (error) {
      return Promise.reject(error)
    }
  })
}

export function useDeletePhotoFormGallery() {
  const { mutate } = useLogout()
  return useMutation({
    mutationFn: async ({ id, refetch, setOpenDeleteModal }) => {
      try {
        const response = await axios.delete(`${BASE_URL}/user/delete-photo-form-gallery/${id}`)
        if (response?.data?.success) {
          refetch()
          toast.success(response?.data?.message)
          setOpenDeleteModal((prevState) => !prevState)
        }
        return response.data
      } catch (error) {
        if (error.response.status === 403) {
          mutate()
        }
        return Promise.reject(error)
      }
    },
    retry: 2,
  })
}

export function useGetRegistrationInfo() {
  return useQuery([`registration-info`], async () => {
    try {
      const { data } = await axios.get(`${BASE_URL}/user/registration-info`)
      return data
    } catch (error) {
      return Promise.reject(error)
    }
  })
}
