import { dataURLtoFile } from './dataURLtoFile'

/* eslint-disable prefer-const */
export const cls = (input) =>
  input
    .replace(/\s+/gm, ' ')
    .split(' ')
    .filter((cond) => typeof cond === 'string')
    .join(' ')
    .trim()

export const customSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    border: '1px solid #e2e8f0', // Border color
    borderRadius: '0.25rem', // Border radius
    padding: '4px',
    backgroundColor: 'rgb(243, 244, 246, 1)',
    boxShadow: state.isFocused ? '0 0 0 2px rgba(66, 153, 225, 0.5)' : 'none', // Box shadow on focus
    '&:hover': {
      borderColor: state.isFocused ? '#e2e8f0' : '#e2e8f0', // Border color on hover
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? 'rgb(243, 244, 246, 1)' : 'white', // Background color on focus
    color: state.isFocused ? '#4299e1' : 'rgb(55, 65, 81, 1)', // Text color on focus
    '&:hover': {
      backgroundColor: '#edf2f7', // Background color on hover
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'rgb(100, 116, 139, 1)',
    fontWeight: 500,
  }),
}

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180
}

export default async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  const maxSize = Math.max(image.width, image.height)
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea
  canvas.height = safeArea

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2)
  ctx.rotate(getRadianAngle(rotation))
  ctx.translate(-safeArea / 2, -safeArea / 2)

  // draw rotated image and store data.
  ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5)

  const data = ctx.getImageData(0, 0, safeArea, safeArea)

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
    0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y,
  )

  // As Base64 string
  // return canvas.toDataURL("image/jpeg");
  return canvas
}

export const getCroppedFile = async ({ imageSrc, crop, setCroppedImage }) => {
  if (!crop || !imageSrc) {
    return
  }

  const canvas = await getCroppedImg(imageSrc, crop)
  const reader = new FileReader()

  // let test
  canvas.toBlob(
    (blob) => {
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        // console.log('inside')
        dataURLtoFile(reader.result, 'dars.jpeg', setCroppedImage)
      }
    },
    'image/jpeg',
    0.95,
  )
}
