/* eslint-disable no-unused-expressions */
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import { Link } from 'react-router-dom'
import ProfileDropdown from './profile-dropdown'

function SideNavbar({ open, handleOpen, loggedInUserInfo }) {
  return (
    <Drawer
      open={open}
      onClose={handleOpen}
      direction='left'
      className='lg:hidden relative'
      style={{ zIndex: '99999' }}
    >
      <div className='px-4 pt-2 h-full bg-gray-900 text-white'>
        {loggedInUserInfo ? (
          <p className='flex items-center justify-between gap-x-2 mt-4'>
            {loggedInUserInfo?.name} <ProfileDropdown />
          </p>
        ) : null}

        <div className='flex flex-col gap-y-4 mt-10'>
          {loggedInUserInfo ? (
            <Link to={loggedInUserInfo?.role === 'admin' ? '/admin' : '/dashboard'}>Dashboard</Link>
          ) : (
            <Link to='/'>Home</Link>
          )}

          <a href='#about'>About</a>
          <a href='#video-gallery'>Video</a>
          <a href='#photo-gallery'>Photo</a>
          <a href='#contact-us'>Contact</a>

          {!loggedInUserInfo ? (
            <Link
              to='/sign-in'
              className={({ isActive }) => `${isActive ? 'underline' : null} hover:text-gray-500`}
            >
              Login
            </Link>
          ) : null}
        </div>
      </div>
    </Drawer>
  )
}

export default SideNavbar
