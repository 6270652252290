/* eslint-disable no-nested-ternary */
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { IoMdClose } from 'react-icons/io'

export default function Modal({ isOpen, closeModal, title, children, size = 'small', className }) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-10' style={{ zIndex: '999999' }} onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-25' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-2 md:p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel
                className={`w-full ${
                  size === 'small' ? 'max-w-xl' : size === 'medium' ? 'max-w-3xl' : 'max-w-6xl'
                } transform overflow rounded-lg bg-white p-3 md:p-6 text-left align-middle shadow-xl transition-all ${className}`}
              >
                <div className='flex justify-between items-center pb-2'>
                  <Dialog.Title
                    as='h3'
                    className='text-lg font-bold leading-6 text-gray-900'
                    dangerouslySetInnerHTML={{ __html: title }}
                  />

                  <button
                    onClick={closeModal}
                    className='h-10 w-10 bg-indigo-50 hover:bg-indigo-100 text-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 rounded-full flex items-center justify-center'
                  >
                    <IoMdClose />
                  </button>
                </div>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
