/* eslint-disable react/display-name */
import { forwardRef } from 'react'
import { cls } from 'utilities/helpers'

const classes = {
  base: 'focus:outline-none transition w-full justify-center shadow-sm sm:w-auto sm:text-sm ease-in-out duration-300 text-base rounded-md font-medium',
  disabled: 'opacity-50 cursor-not-allowed',
  pill: 'rounded-full',
  size: {
    small: 'px-2 py-1 text-sm',
    normal: 'px-6 py-2',
    large: 'px-8 py-3 text-lg',
  },
  variant: {
    primary:
      'bg-primary hover:bg-gray-700 focus:ring-2 focus:ring-gray-700 focus:ring-opacity-50 text-white focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm',
    secondary:
      'w-full border border-gray-300 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm',
    danger:
      'w-full border border-red-500 bg-red-500 text-base font-medium text-white shadow-sm hover:bg-red-600 focus:ring-2 focus:ring-redd-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm',
    dark: 'w-full border border-slate-900 bg-slate-800 text-base font-medium text-white hover:bg-slate-900 focus:ring-2 focus:ring-slate-900 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm',
    success:
      'w-full border border-green-500 bg-green-500 text-base font-medium text-white hover:bg-green-600 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm',
    warning:
      'w-full border border-amber-500 bg-amber-500 text-base font-medium text-white hover:bg-amber-600 focus:ring-2 focus:ring-amber-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm',
  },
}

const Button = forwardRef(
  (
    {
      children,
      type = 'button',
      className,
      variant = 'primary',
      size = 'normal',
      pill,
      fullWidth = false,
      disabled = false,
      ...props
    },
    ref,
  ) => (
    <button
      ref={ref}
      disabled={disabled}
      type={type}
      className={cls(`
                ${classes.base}
                ${classes.size[size]}
                ${classes.variant[variant]}
                ${pill && classes.pill}
                ${fullWidth ? 'block !w-full' : 'inline-flex'}
                ${disabled && classes.disabled}
                ${className}
            `)}
      {...props}
    >
      {children}
    </button>
  ),
)

export default Button
