/* eslint-disable jsx-a11y/label-has-associated-control */
import { Listbox, Transition } from '@headlessui/react'
import Eye from 'assets/svg/eye'
import EyeOff from 'assets/svg/eye-off'
import * as React from 'react'
import { BsArrowRight } from 'react-icons/bs'
import { RiArrowUpSLine } from 'react-icons/ri'

function Label({ className, ...labelProps }) {
  return (
    <label
      {...labelProps}
      className={`block text-gray-700 font-semibold text-base mb-1 ${className}`}
    />
  )
}

const Input = React.forwardRef(function Input(props, ref) {
  const className = `block w-full bg-gray-100 py-3 px-4 placeholder:text-slate-500 font-medium rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-500 ${props.className}`

  if (props.type === 'textarea') {
    return <textarea {...props} className={className} cols='4' />
  }

  return <input {...props} className={className} ref={ref} />
})

export function TextField({ defaultValue = '', name, label, className, ...rest }) {
  return (
    <div className='mb-4'>
      <Label htmlFor={name}>{label}</Label>
      <Input defaultValue={defaultValue} {...rest} id={name} className={className} />
    </div>
  )
}

export function PasswordField({ name, label, className, ...rest }) {
  const [showPassword, setShowPassword] = React.useState(false)
  return (
    <div className='mb-4 relative'>
      <Label htmlFor={name}>{label}</Label>
      <input
        type={showPassword ? 'text' : 'password'}
        {...rest}
        id={name}
        className={`block w-full bg-gray-100 py-3 px-4 placeholder:text-slate-500 font-medium rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-500 ${className}`}
      />
      <button
        type='button'
        className='absolute top-8 right-2 focus:outline-none h-10 w-10 focus:bg-gray-200 rounded-full flex justify-center items-center'
        onClick={() => setShowPassword(!showPassword)}
      >
        {showPassword ? <EyeOff /> : <Eye />}
      </button>
    </div>
  )
}

export function SelectField({ name, label, handleStateUpdate, options, selected }) {
  const [selectedOption, setSelectedOption] = React.useState(selected || options[0])
  handleStateUpdate(selectedOption)

  return (
    <div className='mb-4'>
      <Label htmlFor={name}>{label}</Label>
      <div className='relative'>
        <Listbox value={selectedOption} onChange={setSelectedOption}>
          <Listbox.Button className='w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-gray-900 focus:border-gray-900 sm:text-sm'>
            <span className='block truncate capitalize'>
              {selectedOption ? selectedOption.title : 'Select an option'}
            </span>
            <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
              <RiArrowUpSLine className='w-5 h-5 text-gray-400 rotate-180' aria-hidden='true' />
            </span>
          </Listbox.Button>
          <Transition
            as={React.Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Listbox.Options className='absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white border border-gray-300 rounded-md shadow-lg max-h-60 focus:outline-none sm:text-sm'>
              {options?.map((option) => (
                <Listbox.Option
                  key={option?.title}
                  value={option}
                  className={({ active }) =>
                    `${active ? 'text-white bg-gray-600' : 'text-gray-900'}
               cursor-default select-none relative py-2 pl-10 pr-4`
                  }
                >
                  {({ active }) => (
                    <>
                      <span
                        className={`${
                          active ? 'font-semibold' : 'font-normal'
                        } block truncate capitalize`}
                      >
                        {option.title}
                      </span>
                      {selectedOption === option && (
                        <span
                          className={`${active ? 'text-white' : 'text-white'}
                      absolute inset-y-0 left-0 flex items-center pl-3`}
                        >
                          <BsArrowRight className='w-5 h-5' aria-hidden='true' />
                        </span>
                      )}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </Listbox>
      </div>
    </div>
  )
}
